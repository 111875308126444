import React from "react";
import './Download.css';

const PrivacyPolicy = () => {
  return (
    <>
      <div
        style={{
          padding: '50px',
          maxWidth: '1200px',
          margin: 'auto',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333',
          textAlign: 'justify' // Justified text alignment
        }}
      ></div>
      <div style={{ padding: '50px', maxWidth: '1200px', margin: 'auto', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333' }}>
        <h2 style={{ color: '#E62188' }}>Privacy Policy of QDTAS Technologies LLP</h2>
        <p><strong>Effective Date:26/09/2024</strong></p>

        <p style={{ textAlign: 'justify' }}>
          Welcome to the privacy policy (“Privacy Policy” or “Policy”) of QDTAS Technologies LLP and its affiliates (collectively, “QDTAS,” “we,” “us,” or “our”).
        </p>

        <h3>1. Introduction</h3>
        <p style={{ textAlign: 'justify' }}>
          QDTAS Technologies LLP provides application and web-based solutions to connect customers seeking specific services with service professionals offering these services. This Policy outlines our practices regarding the collection, storage, usage, processing, and disclosure of personal data you share with us when you access, use, or interact with our website (<a href="https://www.qdtas.com/">https://www.qdtas.com/</a>) or mobile application (‘QDTAS’) (collectively, the “Platform”), or avail of the products or services provided through the Platform (collectively, the “Services”). The services offered to you by service professionals through the Platform are referred to as “Professional Services.”
        </p>
        <p style={{ textAlign: 'justify' }}>
          We are committed to protecting your personal data and respecting your privacy. This Policy explains how we process and use personal data about you.
        </p>

        <h3>2. Agreement to Terms</h3>
        <p style={{ textAlign: 'justify' }}>
          By using the Services, you confirm that you have read and agree to be bound by this Policy and consent to the processing activities described herein. Capitalized terms not defined in this Policy have the meanings ascribed to them in our Terms and Conditions, available at <a href="https://www.qdtas.com/terms">https://www.qdtas.com/terms</a> (“Terms”). Please read this Policy in conjunction with the Terms.
        </p>

        <h3>3. Scope of the Policy</h3>
        <p style={{ textAlign: 'justify' }}>
          This Policy applies to individuals who access or use the Services or otherwise avail of the Professional Services. References to “you” in this Policy refer to an end-user of the Platform.
        </p>

        <h3>4. Data Collection</h3>
        <p style={{ textAlign: 'justify' }}><strong>(a) Types of Personal Data Collected</strong></p>
        <p style={{ textAlign: 'justify' }}>
          We collect various types of personal data about you, including but not limited to:
        </p>
        <ul>
          <li><strong>Contact Data:</strong> Mailing/home address, email addresses, and mobile numbers.</li>
          <li><strong>Identity and Profile Data:</strong> Name, username, photographs, and gender.</li>
          <li><strong>Marketing and Communications Data:</strong> Service requests, feedback, communication preferences, chat, and call records with service professionals.</li>
          <li><strong>Technical Data:</strong> IP address, browser type, device ID, location data, and other technological identifiers.</li>
          <li><strong>Transaction Data:</strong> Details of services availed, credit/debit card details, UPI IDs.</li>
          <li><strong>Usage Data:</strong> Activity on the Platform, booking history, and user interactions.</li>
        </ul>
        <p style={{ textAlign: 'justify' }}><strong>(b) Aggregated Data</strong></p>
        <p style={{ textAlign: 'justify' }}>
          We collect, use, and share aggregated data for any purpose. Aggregated data is not considered personal data as it does not directly or indirectly reveal your identity. If combined with your personal data, it will be treated as personal data in accordance with this Policy.
        </p>

        <h3>5. Data Collection Methods</h3>
        <p style={{ textAlign: 'justify' }}>
          We collect personal data through:
        </p>
        <ul>
          <li><strong>Direct Interactions:</strong> When you create an account, use our Services, participate in promotions or surveys, request marketing communications, or contact us.</li>
          <li><strong>Automated Technologies:</strong> Using cookies, web beacons, pixel tags, and server logs.</li>
          <li><strong>Third Parties:</strong> Analytics providers, advertising networks, and publicly available sources.</li>
        </ul>

        <h3>6. Use of Personal Data</h3>
        <p style={{ textAlign: 'justify' }}>
          Your personal data is collected, stored, processed, and monitored from our principal business location at Pune, Maharashtra, India. We use your personal data for the following purposes:
        </p>
        <ul>
          <li>To verify your identity and register you as a user.</li>
          <li>To provide and improve the Services and enable Professional Services.</li>
          <li>To monitor trends, usage, and activity patterns for service enhancement.</li>
          <li>To improve and personalize your experience on the Platform.</li>
          <li>To track transactions and process payments securely.</li>
          <li>To send notifications, updates, and promotional content about the Services.</li>
          <li>To perform business analytics, research, and data analysis to enhance performance.</li>
          <li>To comply with legal obligations and protect our business from fraud, abuse, and security risks.</li>
        </ul>

        <h3>7. Disclosure of Personal Data</h3>
        <p style={{ textAlign: 'justify' }}>
          We may share your personal data with:
        </p>
        <ul>
          <li>Service professionals to facilitate Professional Services.</li>
          <li>Entities within the QDTAS group for business operations.</li>
          <li>External third parties, including service providers, analytics firms, payment processors, advertising networks, and regulatory authorities when required by law.</li>
          <li>Legal entities and regulators to comply with applicable laws, enforce our policies, or protect rights, property, and safety.</li>
        </ul>

        <h3>8. Data Security</h3>
        <p style={{ textAlign: 'justify' }}>
          We implement appropriate technical and organizational security measures to protect your personal data from unauthorized access, loss, misuse, alteration, or disclosure. You are responsible for keeping your login credentials confidential and notifying us of any unauthorized use.
        </p>

        <h3>9. Data Retention</h3>
        <p style={{ textAlign: 'justify' }}>
          We retain your personal data as long as necessary to fulfill the purposes outlined in this Policy and comply with legal obligations. Aggregated data may be used indefinitely without further notice.
        </p>

        <h3>10. Business Transitions</h3>
        <p style={{ textAlign: 'justify' }}>
          In the event of a merger, acquisition, corporate restructuring, or sale of assets, your personal data may be transferred to the acquiring entity as part of the transaction.
        </p>

        <h3>11. User Rights</h3>
        <p style={{ textAlign: 'justify' }}>
          You have the right to:
        </p>
        <ul>
          <li>Access and update your personal data.</li>
          <li>Opt out of marketing communications.</li>
          <li>Request deletion of your account and personal data.</li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          For any of the above requests, please contact us at <a href="mailto:privacy@qdtas.com">privacy@qdtas.com</a>.
        </p>

        <h3>12. Cookies</h3>
        <p style={{ textAlign: 'justify' }}>
          We use cookies and similar tracking technologies to enhance user experience. Some third-party cookies may also be present on our Platform, which we do not control. For more details, refer to our Cookie Policy.
        </p>

        <h3>13. Updates to this Policy</h3>
        <p style={{ textAlign: 'justify' }}>
          We may update this Policy periodically. If significant changes are made, we will notify you through the Platform or other means. Your continued use of the Platform after such updates signifies your consent to the revised Policy.
        </p>

        <h3>14. Contact Information</h3>
        <p style={{ textAlign: 'justify' }}>
          For any questions, grievances, or feedback regarding this Policy, please contact our Grievance Officer:
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>Grievance Officer</strong><br />
          Sandip Dalavi
          <br />
          QDTAS Technologies LLP<br />
          [Registered Address, Pune, Maharashtra, India]<br />
          Email: <a href="mailto:privacy@qdtas.com">privacy@qdtas.com</a><br />
          Phone: +91 98607 4865 / +91 93566 60761 / 020 4422 7377
        </p>
        <p style={{ textAlign: 'justify' }}>
          Thank you for using QDTAS Technologies LLP. We are committed to protecting your privacy and ensuring the security of your personal data.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;